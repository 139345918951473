<template>
  <div class="vip">
    <div class="head btn-black">
      <img :src="user.avatar">
      <div>
        <h4>{{ user.nickname }}</h4>
        <h6>{{ detail.vipinfo }}</h6>
      </div>
    </div>

    <div class="content">
      <div class="card" :style="imgSrc">
        <p class="text-right top">
          <img src="@assets/images/logo.png">
          在线学习VIP学习卡
        </p>
        <p>{{ detail.is_forever == 1 ? '永久' :  detail.valid_date+'天'}}<br />￥{{ detail.money }}元</p>
        <p class="font-28">{{ detail.intro }}</p>
      </div>

      <div v-html="detail.explain"></div>
    </div>

    <div class="btn-bottom">
      <button class="btn-black" @click="pay">{{ detail.istrue ? '立即续费' : '立即开通' }}</button>
    </div>
  </div>
</template>

<script>
import { getUser, vipDetail, vipCreate } from "@api/user";
export default {
  data() {
    return {
      id: '',
      user: {},
      detail: {},
      imgSrc: {
        backgroundImage: "url("+require('@assets/images/user_bg.png')+")"
      }
    }
  },
  created() {
    this.id = this.$route.params.id
    console.log(this.$route.params.id)
    this.getUser()
    this.getData()
  },
  methods: {
    // 获取用户数据
    getUser() {
      let that = this;
      getUser().then(res => {
        that.user = res.data
      })
    },

    // 获取vip详情
    getData() {
      let that = this;
      vipDetail(that.id).then(res => {
        that.detail = res.data
      }).catch(err => {
        that.$dialog.error(err.msg)
      })
    },

    // 支付
    pay() {
      let that =this;

      vipCreate(that.id).then(res => {
        const order_id = res.data.order_id

        that.$router.push({
          path: '/lesson/pay',
          query: {order_id: order_id, sign: 'vip'}
        })
      }).catch(err => {
        that.$dialog.error(err.msg)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .vip {
  padding-bottom: 1.5rem;
  .head {
    width: 100%;
    height: 2rem;
    color: #E6C3A0;
    padding: 0 .3rem;
    display: flex;
    align-items: center;
    img {
      width: .8rem;
      height: .8rem;
      border-radius: 50%;
    }
    div {
      padding-left: .1rem;
      h4 {
        font-weight: 500;
        font-size: .28rem;
      }
      h6 {
        font-weight: 500;
        font-size: .24rem;
      }
    }
  }
  .content {
    .card {
      width: 100%;
      height: 4rem;
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      color: #E6C3A0;
      padding: .6rem .3rem .3rem;
      font-size: .26rem;
      margin-bottom: .2rem;
      .top {
        position: relative;
        img {
          width: 2.4rem;
          position: absolute;
          left: -.36rem;
          top: -.3rem;
        }
      }
    }
    background: white;
    padding: .2rem .3rem;
    h4 {
      font-size: .28rem;
      margin: .2rem 0;
    }
    > p {
      font-size: .26rem;
      line-height: .42rem;
    }
  }
}
</style>